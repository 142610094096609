<template>
  <div
    v-editable="blok"
    class="prose-sm fill-current"
    :class="blok.no_margin ? 'no-margin' : marginClasses"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
  >
    <RichTextRenderer
      v-if="blok.body"
      :class="{
        '[&>p:first-child]:!mt-0': noMarginTop,
      }"
      :document="blok.body"
    />
  </div>
</template>

<script setup lang="ts">
import type { SbText } from '~/storyblok/types/storyblok'

const props = defineProps({
  blok: {
    type: Object as PropType<SbText>,
    required: true,
  },
  noMarginTop: {
    type: Boolean,
    default: false,
  },
})

const { marginClasses } = useStoryblokMargins(props.blok)
</script>

<style lang="scss">
@media only screen and (width >= 768px) {
  .no-margin {
    h1,
    h2,
    h3,
    h4,
    p {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}
</style>
